iframe#launcher {
  z-index: 1050 !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* on exit animation, the style within the component gets removed immediately,
defining some bare minimum here globally to make the animation look better  */

input {
  padding: 22px 12px 10px;
  border: 1px solid #e9eaec;
  width: 100%;
  font-size: 16px; /* suggested workaround for iOS Safari input zoom */
}

label {
  font-size: 12px;
}

.smallIcon {
  width: 16px;
  height: 16px;
}

.ctaGroup {
  margin-top: 16px;
  gap: 16px;
  display: flex;
}

.ctaGroup > div {
  flex-basis: 50%;
}

.finn-social-button {
  width: 100%;
  height: 60px;
  text-align: center;
}

.finn-social-button-separator {
  text-align: center;
}

.scroll-touch {
  -webkit-overflow-scrolling: touch;
}
.scroll-touch::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0;
}
